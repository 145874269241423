import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { DB } from "~/types/shared";
dayjs.extend(duration);

export const toOneDecimalPlaces = (n: number) => Math.round(n * 10) / 10;
export const toTwoDecimalPlaces = (n: number) => Math.round(n * 100) / 100;

const m2f = 3.28084;
const c2f = 1.8;
const d = 32;
const kg2lbs = 2.20462;
const l2ft3 = 28.3168;
const bar2Psi = 14.5038;
const kelvin2Celsius = 273.15;

export const kilosToPounds = (n: number | string) =>
  toOneDecimalPlaces(Number(n) * kg2lbs);
export const poundsToKilos = (n: number | string) =>
  toOneDecimalPlaces(Number(n) / kg2lbs);
export const metresToFeet = (n: number | string) => Number(n) * m2f;
export const feetToMetres = (n: number | string) => Number(n) / m2f;
export const kelvinToCelcius = (n: number | string) =>
  n !== undefined ? toOneDecimalPlaces(Number(n) - kelvin2Celsius) : undefined;
export const celsiusToFahrenheit = (n: number | string) =>
  toOneDecimalPlaces(Number(n) * c2f + d);
export const fahrenheitToCelsius = (n: number | string) =>
  toOneDecimalPlaces((Number(n) - d) / c2f);
export const minutesToTime = (value: number | string) =>
  dayjs.duration(Number(value), "minutes").format("HH:mm");
export const secondsToTime = (value: number | string, showHours?: boolean) =>
  dayjs
    .duration(Number(value), "seconds")
    .format(showHours ? "H:mm:ss" : "mm:ss");

export const minutesToTimeLong = (value: number | string) => {
  const v = Number(value);
  const days = Math.floor(v / 1440);
  const hours = Math.floor((v % 1440) / 60);
  const minutes = Math.floor(v % 60);

  const hoursAndMinutes = `${hours}h ${minutes}m`;

  if (days) {
    return `${days}d ${hoursAndMinutes}`;
  }

  return hoursAndMinutes;
};
export const litresToCubicFeet = (n: number | string) =>
  toOneDecimalPlaces(Number(n) / l2ft3);
export const cubicFeetToLitres = (n: number | string) =>
  toOneDecimalPlaces(Number(n) * l2ft3);
export const barToPsi = (n: number | string) =>
  toOneDecimalPlaces(Number(n) * bar2Psi);
export const psiToBar = (n: number | string) =>
  toOneDecimalPlaces(Number(n) / bar2Psi);

const fieldLabels = {
  min_water_temp: "Min Water Temp",
  max_water_temp: "Max Water Temp",
  air_temp: "Air Temp",
  max_depth: "Max Depth",
  pressure_start: "Pressure Start",
  pressure_end: "Pressure End",
  tank_size: "Tank Size",
  dive_duration: "Dive duration",
  entry_date: "Entry Date",
  entry_time: "Entry Time",
  water_type: "Water Type",
} as const;

type FieldKeys = keyof typeof fieldLabels;

export const getUnitLabel = ({
  key,
  preferences,
}: {
  key: FieldKeys;
  preferences: DB["user_profiles"]["Row"];
}) => {
  const {
    preferred_temperature_unit,
    preferred_depth_unit,
    preferred_pressure_unit,
  } = preferences;
  if (key === "min_water_temp" || key === "max_water_temp") {
    return preferred_temperature_unit === "celsius" ? "°C" : "°F";
  }

  if (key === "max_depth") {
    return preferred_depth_unit === "metres" ? "m" : "ft";
  }

  if (key === "dive_duration") {
    return "min";
  }

  if (key === "pressure_start" || key === "pressure_end") {
    return preferred_pressure_unit === "bar" ? "BAR" : "PSI";
  }

  return "";
};
