export const weights = {
  kg: "kg",
  pounds: "lbs",
} as const;

export const distance = {
  metres: "m",
  feet: "ft",
} as const;

export const temperature = {
  celsius: "°C",
  fahrenheit: "°F",
} as const;

export const pressure = {
  psi: "PSI",
  bar: "BAR",
} as const;

export const volume = {
  litres: "L",
  "cubic-feet": "ft³",
} as const;
